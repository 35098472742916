@media screen and (max-width: 1200px) and (min-width: 240px) {
    .page_width {
        width: 100%;
        padding: 0px 15px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 240px) {
    .gerenric_heading {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .gerenric_product.full_column {
        margin: 0px;
    }

    .gerenric_product .pd_col {
        width: 100%;
    }

    .gerenric_product.four_column .pd_col {
        width: 100%;
    }

    .gerenric_input {
        padding: 16px 16px;
    }

    .gerenric_form ul li .col_left {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 15px;
    }

    .gerenric_form ul li .col_right {
        width: 100%;
        padding-left: 0px;
    }


    #header_section {
        z-index: 999999999;
        min-height: 55px;
    }

    #header_section.headersticky {
        min-height: 55px;
        display: flex !important;
        align-items: center;
    }

    .menu_icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 0%;
        cursor: pointer;
        display: flex !important;
        position: absolute;
        left: 0px;
        top: -7px;
    }

    .menu_icon:hover .Mbarline {
        width: 25px;
    }

    .menu_icon.closeMenu .Mbarline {
        width: 25px;
    }

    .menu_icon.closeMenu .Mbarline:first-child {
        -webkit-transform: translateY(7px) rotate(45deg);
        transform: translateY(7px) rotate(45deg);
    }

    .menu_icon.closeMenu .Mbarline:nth-child(2) {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    .menu_icon.closeMenu .Mbarline:last-child {
        -webkit-transform: translateY(-7px) rotate(-45deg);
        transform: translateY(-7px) rotate(-45deg);
    }

    .Mbarline {
        width: 25px;
        height: 2px;
        background: #000;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    .Mbarline:nth-child(2) {
        width: 25px;
        margin: 5px 0;
    }

    .Mbarline:last-child {
        width: 25px;
    }

    #logo {
        width: 70px;
        left: 40px;
        top: unset;
    }

    #navigation_section ul {
        margin-top: 0px;
    }

    #navigation_section {
        padding: 0px;
        display: none;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        left: 0px;
        top: 55px !important;
    }

    #navigation_section ul {
        width: 230px;
        display: block;
        overflow-y: auto;
        background-color: #ffff;
        height: 100%;
        position: absolute;
        z-index: 99;
        left: 0px;
    }

    #navigation_section::after {
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(0, 0, 0, 0.30);
        position: absolute;
        left: 0px;
        top: 0px;
    }

    #navigation_section.showMenu {
        display: block;
        transition: all 0.3s;
    }

    #navigation_section ul {
        padding-bottom: 100px;
    }

    #navigation_section ul li {
        display: block;
        text-align: left;
        font-size: 14px;
        padding: 15px 10px 15px 15px;
        border-bottom: 1px solid #ccc;
    }

    #navigation_section ul li a {
        position: relative;
    }

    #header_section.headersticky #logo {
        width: 70px;
        left: 40px;
    }

    #header_section.headersticky .headerinner {
        padding-top: 0px;
    }

    #header_section .audit_button .gerenric_button {
        height: 40px;
        min-width: 120px;
        font-size: 16px;
        padding: 5px 10px;
    }

    #banner_section {
        height: 450px;
    }

    #banner_section .banner_image img {
        height: 450px;
    }

    #banner_section .banner_detail .banner_title {
        font-size: 40px;
    }

    #banner_section .banner_detail .banner_info {
        font-size: 20px;
    }

    #banner_section .banner_detail .banner_app_button {
        width: 60%;
        margin: auto;
    }

    #banner_search .banner_search_inner {
        width: 95%;
        display: block;
        height: auto;
    }

    #banner_search .banner_search_div {
        width: calc(100% - 0px);
        padding-left: 0px;
        margin-top: 10px;
    }

    .app_download_section {
        min-height: 300px;
    }

    .app_download_section .app_title {
        font-size: 45px;
    }

    .app_download_section .app_button {
        width: 40%;
    }

    .professional_section .professional_col {
        width: 100%;
    }

    .professional_section .professional_item .professional_list {
        min-height: 160px;
    }

    .register_page .register_inner {
        height: auto;
        position: relative;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
    }

    .register_page .register_left {
        width: 100%;
    }

    .register_page .register_right {
        width: 90%;
        position: absolute;
        z-index: 9;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.60);
        padding: 20px;
        border-radius: 10px;
    }

    .register_page .register_right .gerenric_form {
        background-color: transparent;
    }

    #footer_section .footer_col {
        width: 100%;
        padding-left: 0px;
    }

}

@media screen and (max-width: 800px) and (min-width: 240px) {}

@media screen and (max-width: 650px) and (min-width: 240px) {
    .gerenric_heading {
        font-size: 25px;
    }

    #banner_section {
        height: 350px;
    }

    #banner_section .banner_image img {
        height: 350px;
    }

    #banner_section .banner_detail {
        width: 95%;
    }

    #banner_section .banner_detail .banner_title {
        font-size: 30px;
    }

    #banner_section .banner_detail .banner_info {
        font-size: 15px;
    }

    #banner_search {
        height: auto;
        bottom: 40px;
    }

    #banner_search .banner_search_inner {
        padding: 15px;
    }

    #banner_search .banner_search_div {
        padding-right: 0px;
    }

    #banner_search .banner_search_input {
        height: 50px;
    }

    #banner_search .gerenric_button {
        position: inherit;
        margin-top: 10px;
        height: 50px;
        min-width: inherit;
        width: 100%;
    }

    .how_work_section.gerenric_padding {
        padding-top: 175px;
    }

    .app_download_section {
        min-height: 220px;
        background-size: 400px;
    }

    .app_download_section .app_title {
        font-size: 25px;
    }

    .app_download_section .app_button {
        width: 70%;
    }


}

@media screen and (max-width: 480px) and (min-width: 240px) {}

@media screen and (max-width: 380px) and (min-width: 240px) {}

@media screen and (max-width: 320px) and (min-width: 240px) {}

@media screen and (max-width: 270px) and (min-width: 240px) {}