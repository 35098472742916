@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,light,Semibold,bold,300,600,700,800);

body {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #838383;
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
div,
ol,
span,
input,
textarea,
div,
class,
select,
section,
header,
footer,
img {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#container {
    width: 100%;
}

.full_width {
    width: 100%;
}

.page_width {
    width: 1200px;
    text-align: left;
}

.left {
    float: left;
}

.right {
    float: right;
}

.left_side {
    float: left;
}

.right_side {
    float: right;
}

.col {
    float: left;
}

.clearfix {
    clear: both;
}

a img {
    border: 0px;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

.hide {
    display: none;
}

.wd_100 {
    width: 100%;
}

.wd_95 {
    width: 95%;
}

.wd_90 {
    width: 90%;
}

.wd_85 {
    width: 85%;
}

.wd_80 {
    width: 80%;
}

.wd_75 {
    width: 75%;
}

.wd_70 {
    width: 70%;
}

.wd_65 {
    width: 65%;
}

.wd_60 {
    width: 60%;
}

.wd_55 {
    width: 55%;
}

.wd_50 {
    width: 50%;
}

.wd_45 {
    width: 45%;
}

.wd_40 {
    width: 40%;
}

.wd_35 {
    width: 35%;
}

.wd_30 {
    width: 30%;
}

.wd_25 {
    width: 25%;
}

.wd_20 {
    width: 20%;
}

.wd_15 {
    width: 15%;
}

.wd_10 {
    width: 10%;
}

.txt_align_left {
    text-align: left;
}

.txt_align_center {
    text-align: center;
}

.txt_align_right {
    text-align: right;
}

.position_relative {
    position: relative;
}

p {
    font-size: 15px;
    color: #000;
    text-align: left;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
}

h1 {
    font-size: 28px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

h2 {
    font-size: 22px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

h3 {
    font-size: 20px;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin-bottom: 15px;
}

a {
    color: #000000;
}

/*GERENRIC_SECTION_START*/
.slick-prev,
.slick-next {
    display: none !important;
}

.gerenric_padding {
    padding: 60px 0px;
}

.gerenric_heading {
    width: 100%;
    font-size: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
}

.gerenric_button {
    min-width: 180px;
    height: 40px;
    border-radius: 10px;
    background-color: #0D3E99;
    border: 0px;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.gerenric_button.button_full {
    width: 100%;
    min-width: inherit;
}

.gerenric_input {
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    color: #091C27;
    border: 1px solid #7A8C94;
    border-radius: 4px 4px 4px 4px;
    padding: 16px 24px 16px 24px;
}

.gerenric_input:focus {
    outline: none;
}

.gerenric_input.gerenric_textarea {
    width: 100%;
    resize: none;
    height: 130px;
}

.gerenric_form {
    width: 100%;
    background-color: #fff;
}

.gerenric_form ul li {
    list-style-type: none;
    margin-bottom: 15px;
    color: #000;
    font-size: 16px;
}

.gerenric_form ul li .form_row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gerenric_form ul li .col_left {
    width: 50%;
    padding-right: 10px;
}

.gerenric_form ul li .col_right {
    width: 50%;
    padding-left: 10px;
}

.gerenric_form ul li a {
    color: #0D3E99;
    text-decoration: underline;
    font-weight: 600;
}

.gerenric_product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gerenric_product.full_column {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
}

.gerenric_product.full_column .pd_col {
    width: 100%;
    padding: 0px 20px;
}

.gerenric_product.full_column .slick-list {
    padding: 30px 0px !important;
}

.gerenric_product.four_column .pd_col {
    width: 25%;
}

.gerenric_product.four_column .pd_image img {
    width: 100%;
    height: 190px;
}

.gerenric_product .pd_col {
    width: 33.333%;
    padding: 15px;
}

.gerenric_product .pd_item {
    width: 100%;
    text-align: center;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.gerenric_product .pd_icon {
    width: 100%;
    height: 150px;
    text-align: center;
    padding: 20px 20px 0px 20px;
}

.gerenric_product .pd_icon img {
    display: block;
    margin: auto;
}

.gerenric_product .pd_image {
    width: 100%;
}

.gerenric_product .pd_image img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
}

.gerenric_product .pd_detail {
    width: 100%;
    padding: 20px 27px 30px 27px;
}

.gerenric_product .pd_detail .pd_title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.gerenric_product .pd_detail p {
    font-size: 15px;
    height: 50px;
    overflow: hidden;
    text-align: center;
    color: #000;
    margin-bottom: 20px;
}

.gerenric_product .designation_title {
    width: 100%;
    font-size: 18px;
    color: #0D3E99;
    text-align: center;
    margin-bottom: 10px;
}

.gerenric_product .rating_star {
    display: flex;
    justify-content: center;
}

.gerenric_category.gerenric_product .pd_item {
    border-radius: 10px;
    display: flex;
    padding: 10px;
}

.gerenric_category.gerenric_product .pd_image {
    width: 80px;
    height: 80px;
}

.gerenric_category.gerenric_product .pd_image img {
    height: 80px;
    border-radius: 10px;
}

.gerenric_category.gerenric_product .pd_detail {
    width: calc(100% - 80px);
    padding: 0px;
    padding-left: 10px;
}

.gerenric_category.gerenric_product .pd_detail .pd_title {
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
}

.gerenric_category.gerenric_product .pd_detail p {
    font-size: 13px;
    margin-bottom: 5px;
    text-align: left;
    height: 38px;
}

.gerenric_category.gerenric_product .pd_detail .pd_prise {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    color: #0D3E99;
}

.gerenric_category.gerenric_product .rating_star {
    display: flex;
    justify-content: flex-start;
}

.gerenric_category.gerenric_product .rating_star img {
    width: 18px;
}

/*GERENRIC_SECTION_END*/

/*HEADER_SECTION_START*/
.menu_icon {
    display: none;
}

#header_section {
    width: 100%;
    min-height: 80px;
    background-color: #ffffff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.10);
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 99999;
    display: flex;
    top: 0px;
    align-items: center;
    padding: 20px 0px 20px 0px;
}

#header_section .page_width {
    width: 1200px;
    margin: auto;
}

#header_section.headersticky {
    padding: 10px 0px 10px 0px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.10);
    z-index: 99999;
    position: fixed;
    background-color: #fff;
}

#header_section .headerinner {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

#logo {
    width: 100px;
    max-height: 50px;
    cursor: pointer;
    position: absolute;
    left: 0px;
    z-index: 9999;
    top: 5px;
}

#logo img {
    display: block;
}

#header_section.headersticky #logo {
    width: 100px;
    display: block;
}

#navigation_section {
    width: 100%;
    position: relative;
    text-align: center;
    padding-left: 180px;
    padding-right: 125px;
    z-index: 2;
}

#navigation_section ul {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 10px;
}

#navigation_section ul li {
    list-style-type: none;
    text-transform: uppercase;
    position: relative;
    padding: 0px 15px;
    display: inline-flex;
    font-weight: 600;
    font-size: 18px;
    color: #000;
}

#navigation_section ul li a {
    color: #000;
}

#navigation_section ul li .gerenric_button {
    border-radius: 100px;
}

#navigation_section ul li a:hover {
    color: #0D3E99;
}

#header_section .header_language {
    position: absolute;
    right: 0px;
    z-index: 99;
    margin-top: 10px;
}

#header_section .header_language .language_select {
    width: 125px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100px;
    padding: 5px 10px;
}

#header_section .header_language .language_select .fa-caret-down {
    position: absolute;
    right: 15px;
}

#header_section .header_language:hover ul {
    display: block;
}

#header_section .header_language ul {
    width: 100%;
    display: none;
    position: absolute;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
}

#header_section .header_language ul li {
    width: 100%;
    display: flex;
    align-items: center;
    list-style-type: none;
    border-bottom: 1px solid #ddd;
    padding: 7px 10px;

    &:hover {
        cursor: pointer;
    }
}

#header_section .header_language ul li:last-child {
    border-bottom: 0px;
}

#header_section .header_language a {
    display: flex;
    align-items: center;
}

#header_section .header_language img {
    margin-right: 5px;
    width: 20px;
}

/*HEADER_SECTION_END*/

/*BANNER_SECTION_START*/
#banner_section {
    width: 100%;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#banner_section .banner_block {
    width: 100%;
}

#banner_section .banner_image {
    width: 100%;
    position: relative;
}

#banner_section .banner_image::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0px;
    background-color: #0D3E99;
    opacity: 0.30;
}

#banner_section .banner_image img {
    width: 100%;
    height: 650px;
    object-fit: cover;
}

#banner_section .page_width {
    position: absolute;
    height: 100%;
    display: flex;
    left: 0%;
    right: 0%;
    margin: auto;
    align-items: center;
    justify-content: center;
}

#banner_section .banner_detail {
    width: 75%;
    margin: auto;
    position: absolute;
    left: 0%;
    right: 0%;
    z-index: 999;
    text-align: center;
}

#banner_section .banner_detail .banner_title {
    width: 100%;
    font-size: 50px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}

#banner_section .banner_detail .banner_title span {
    color: #FF4F4F;
}

#banner_section .banner_detail .banner_info {
    width: 100%;
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 30px;
}

#banner_section .banner_detail .banner_app_button {
    display: flex;
    justify-content: center;
}

#banner_section .banner_detail .banner_app_button a {
    margin: 0px 5px;
}

#banner_search {
    width: 100%;
    height: 120px;
    bottom: -60px;
    position: absolute;
}

#banner_search .banner_search_inner {
    width: 80%;
    height: 120px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    margin: auto;
}

#banner_search .banner_search_title {
    font-size: 21px;
    color: #313131;
    font-weight: 600;
}

#banner_search .banner_search_div {
    width: calc(100% - 180px);
    position: relative;
    padding: 0px 190px 0px 10px;
}

#banner_search .banner_search_input {
    width: calc(100% - 0px);
    height: 60px;
    font-size: 20px;
    padding: 8px 20px;
    border: 1px solid #cccccc;
    border-radius: 100px;
}

#banner_search .gerenric_button {
    min-width: 170px;
    background-color: #000;
    position: absolute;
    right: 0px;
    border-radius: 100px;
    height: 60px;
}

/*BANNER_SECTION_END*/

/*CONTENT_SECTION_START*/
#content_section {
    width: 100%;
}

.how_work_section {
    width: 100%;
    background-color: #ECECEC;
}

.how_work_section.gerenric_padding {
    padding-top: 110px;
}

.category_section {
    width: 100%;
    background-color: #ffffff;
}

.app_download_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    background: #0D3E99 url("../assets/images/app_download_bg.png") right center no-repeat;
}

.app_download_section .app_download_detail {
    width: 100%;
}

.app_download_section .app_title {
    font-size: 60px;
    color: #fff;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 40px;
}

.app_download_section .app_button {
    width: 100%;
    display: flex;
}

.app_download_section .app_button a {
    margin-right: 10px;
}

.winter_section {
    width: 100%;
    background-color: #ffffff;
}

.professional_section {
    width: 100%;
    background-color: #ECECEC;
}

.professional_section .professional_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.professional_section .professional_col {
    width: 33.333%;
    padding: 15px;
}

.professional_section .professional_item {
    width: 100%;
    text-align: center;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}

.professional_section .professional_item .professional_title {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    font-size: 20px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #404040;
    font-weight: 600;
    color: #fff;
}

.professional_section .professional_item .professional_list {
    width: 100%;
    min-height: 250px;
    padding: 20px;
}

.professional_section .professional_item .professional_list ul li {
    list-style-type: none;
    min-height: 20px;
    text-align: left;
    position: relative;
    padding-left: 30px;
    font-size: 17px;
    color: #000;
    margin-bottom: 15px;
}

.professional_section .professional_item .professional_list ul li a {
    color: #000;
}

.professional_section .professional_item .professional_list ul li a:hover {
    color: #0D3E99;
}

.professional_section .professional_item .professional_list ul li::after {
    width: 19px;
    height: 15px;
    content: '';
    position: absolute;
    left: 0px;
    top: 4px;
    background: url("../assets/images/list_ok.svg")0px 0px no-repeat;
}

.expert_section {
    width: 100%;
    background-color: #ffffff;
}

.register_page {
    width: 100%;
    padding: 50px 0px 50px 0px;
}

.register_page .register_inner {
    width: 100%;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.20);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    height: 600px;
    background-color: #fff;
    border-radius: 30px;
}

.register_page .register_left {
    width: 50%;
    position: relative;
}

.register_page .register_left::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0px;
    background-color: #0D3E99;
    opacity: 0.30;
}

.register_page .register_left img {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.register_page .register_right {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 40px 60px;
    background-color: #fff;
}

.how_work_page {
    width: 100%;
}

.categroy_page {
    width: 100%;
}

/*CONTENT_SECTION_END*/

/*FOOTER_SECTION_START*/
#footer_section {
    width: 100%;
    background-color: #161616;
    padding: 40px 0px;
}

#footer_section .footer_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

#footer_section .footer_col {
    width: 33.333%;
    padding-left: 100px;
}

#footer_section .footer_col:nth-child(1) {
    padding-left: 0px;
}

#footer_section .footer_title {
    width: 100%;
    font-size: 25px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}

#footer_section p {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
}

#footer_section ul li {
    list-style-type: none;
    font-weight: 400;
    display: flex;
    font-size: 15px;
    color: #fff;
    margin-bottom: 10px;
}

#footer_section ul li a {
    color: #fff;
}

#footer_section ul li a:hover {
    text-decoration: underline;
}

/*FOOTER_SECTION_END*/

.job_detail_page {
    width: 100%;
}

.job_detail_page .job_detail_inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.job_detail_page .job_detail_left {
    width: 500px;
}

.job_detail_page .job_detail_left .detail_image {
    width: 100%;
    padding: 20px;
}

.job_detail_page .job_detail_left .detail_image img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    height: 420px;
    object-fit: cover;
}

.job_detail_page .job_detail_left .detail_image_thum {
    width: 100%;
    padding: 5px;
}

.job_detail_page .job_detail_left .detail_image_thum img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
}

.job_detail_page .job_detail_right {
    width: calc(100% - 500px);
    padding-left: 40px;
}

.job_detail_page .job_detail_right .gerenric_heading {
    text-align: left;
    margin-bottom: 10px;
}

.job_detail_page .job_detail_right ul li {
    list-style-type: none;
    font-size: 15px;
    color: #000;
    margin-bottom: 15px;
}

.job_detail_page .job_detail_right ul li span {
    width: 100px;
    font-weight: bold;
}

.job_detail_page .job_detail_right .job_detail_subtitle {
    font-size: 17px;
    color: #0D3E99;
    margin-bottom: 10px;
}

@media screen and (max-width: 1024px) and (min-width: 240px) {
    .job_detail_page .job_detail_left {
        width: 100%;
        margin-bottom: 20px;
    }

    .job_detail_page .job_detail_right {
        width: calc(100% - 0px);
        padding-left: 0px;
    }
}

@media screen and (max-width: 480px) and (min-width: 240px) {
    .job_detail_page .job_detail_left .detail_image img {
        height: 350px;
    }

    .job_detail_page .job_detail_left .detail_image_thum img {
        height: 70px;
    }
}


.profile_page {
    width: 100%;
}

.profile_page .profile_inner {
    width: 90%;
    margin: auto;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
}

.profile_page .profile_image {
    width: 350px;
}

.profile_page .profile_image img {
    width: 350px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
    height: 400px;
    object-fit: cover;
}

.profile_page .profile_detail {
    width: calc(100% - 350px);
    padding-left: 30px;
}

.profile_page .profile_detail ul li {
    list-style-type: none;
    display: flex;
    font-size: 15px;
    color: #000;
    margin-bottom: 20px;
}

.profile_page .profile_detail ul li .pf_title {
    width: 110px;
    font-weight: bold;
}

.profile_page .profile_detail ul li .pf_info {
    width: calc(100% - 110px);
    padding-left: 10px;
}

.user_profile_header {
    position: relative;

    &:hover {
        cursor: pointer;

        .user_menu {
            display: block;
        }
    }

    .user_menu {
        position: absolute;
        width: 200px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        top: 100%;
        text-align: left;
        padding: 10px;
        display: none;

        li {
            font-size: 12px !important;
            text-align: left !important;
            font-style: unset !important;
        }

        .logout_section {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin-left: 5px;
            }
        }
    }
}